import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { TopBanner, TopBannerState, initTopBanner } from "./initialState";

const hashCode = process.env.NEXT_PUBLIC_HASH_CODE;
if (!hashCode) {
  throw new Error("NEXT_PUBLIC_HASH_CODE environment variable is not set");
}

// Function to encrypt topBanner information
const encryptTopBanner = (topBanner: TopBanner) => {
  return CryptoJS.AES.encrypt(JSON.stringify(topBanner), hashCode).toString();
};

// Function to decrypt topBanner information
const decryptTopBanner = (encryptedTopBanner: string) => {
  try {
    return JSON.parse(CryptoJS.AES.decrypt(encryptedTopBanner, hashCode).toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Failed to decrypt topBanner information", error);
    return initTopBanner;
  }
};

export const useTopBannerStore = create<TopBannerState>()(
  persist<TopBannerState>(
    (set, get) => ({
      topBanner: encryptTopBanner(initTopBanner),
      updateTopBanner: (topBanner: TopBanner) => {
        set(
          produce((draft: TopBannerState) => {
            draft.topBanner = encryptTopBanner(topBanner);
          })
        );
      },
      getTopBanner: () => {
        const state = get();

        if (typeof state.topBanner === "object") {
          return state.topBanner;
        } else if (typeof state.topBanner === "string") {
          return decryptTopBanner(state.topBanner);
        } else {
          return initTopBanner;
        }
      }
    }), {
      name: "topBanner"
    } as PersistOptions<TopBannerState>
  )
);
